import services from "../services";
import router from "../router/index";
import Utils from "../Utils";

const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
  return {
    folderList: [],
    activeFolder: {},
    projectList: [],
    noMoreProjectsToLoad:false,
    sort:'dec' // default sorting

  };
};

const state = getDefaultState();

const getters = {
  getFolderList: (state) => state.folderList,
  getActiveFolder: (state) => state.activeFolder,
  getProjectList: (state) => state.projectList,
  getNoMoreProjectsToLoad:(state)=>state.noMoreProjectsToLoad,
  getProjectSortingStatus:(state)=>state.sort
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_FOLDERS_LIST(state, folderList) {
    state.folderList = folderList;
  },
  SET_PROJECT_SORTING(state,sort){
    state.sort = sort;
  },
  RESET_COMPLETE_PROJECT_LIST(state){
    if(state.projectList.length > 2)
      state.projectList[2].list = []
    state.noMoreProjectsToLoad = false
  },
  SET_ACTIVE_FOLDER(state, folder) {
    state.activeFolder = folder;
  },
  SET_PROJECT_LIST(state, projectList) {
    state.projectList = projectList;
  },
  APPEND_TO_PROJECT_LIST(state, projects) { // infinite scroll
    let index;
    projects.forEach((project) => {
      switch (project.status) {
        case Utils.PROJECT_STATUS.PENDING:
          index = 0;
          break;
        case Utils.PROJECT_STATUS.ACTIVE:
          index = 1;
          break;
        case Utils.PROJECT_STATUS.CLOSED:
          index = 2;
          break;
        default:
          index = -1;
          break;
      }
      index > -1 && state.projectList[index].list.push(project);
    });
  },
  REMOVE_PROJECT_FROM_LIST(state, projectId) {
    let statusIndex = -1;
  
    state.projectList.forEach((statusList, index) => {
      const projectIndex = statusList.list.findIndex(project => project._id === projectId);
  
      if (projectIndex !== -1) {
        statusList.list.splice(projectIndex, 1);
        statusIndex = index; 
      }
    });
  
    if (statusIndex === -1) {
      console.warn(`Project with ID ${projectId} not found.`);
    }
  },
  ADD_PROJECT_TO_LIST(state, project) {
    let index;
    switch (project.status) {
      case Utils.PROJECT_STATUS.PENDING:
        index = 0;
        break;
      case Utils.PROJECT_STATUS.ACTIVE:
        index = 1;
        break;
      case Utils.PROJECT_STATUS.CLOSED:
        index = 2;
        break;
      default:
        index = -1;
        break;
    }
      if (index > -1) {
      state.projectList[index].list.push(project);
    } else {
      console.warn(`Invalid project status: ${project.status}`);
    }
  },
  SET_MAX_CLOSED_PROJECTS_REACHED(state,status){
    state.noMoreProjectsToLoad=status
  }
};

const actions = {
  retrieveFoldersByClientId({ commit, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.retrieveFoldersByClientId(clientId)
      .then((response) => {
        return response;
      })
      .then((folders) => {
        commit("SET_FOLDERS_LIST", folders);
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_FOLDERS_LIST", []);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  retrieveFolderByClientId({ commit, rootGetters }, payload) {
    const {folderId,limit,sort} = payload
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.retrieveFolderByClientId(clientId, folderId,limit,sort)
      .then((response) => {
        commit("SET_ACTIVE_FOLDER", response);
        if(response.projects.length === 0 || response.projects.length < limit)
          commit("SET_MAX_CLOSED_PROJECTS_REACHED",true)
        commit("SET_PROJECT_LIST", groupProjects(response.projects));
      })
      .catch((error) => {
        if (error instanceof DOMException && error.name === 'QuotaExceededError') {
          console.error('Storage quota exceeded. Unable to save state.');
          return;
        }
        console.error("Error while retrieving folder data: ", error);
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  retrieveProjectsFromFolder({ commit, rootGetters }, payload = { folderId: '', limit: 0, skip: 0, appendToProjects: false, projectsStatus: '', sort:'dec'}) {
    const { folderId, limit, skip, appendToProjects, projectsStatus,sort } = payload;
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.retrieveFolderByClientId(clientId, folderId, limit, skip, projectsStatus,sort)
      .then((response) => {
        const projects = response.projects;
        
        if (appendToProjects) { // infinite scroll
          commit("APPEND_TO_PROJECT_LIST", projects)
          if(projects.length === 0 || projects.length < limit)
            commit("SET_MAX_CLOSED_PROJECTS_REACHED",true)
        } else {
          commit("SET_PROJECT_LIST", groupProjects(projects));
        }
      })
      .catch((error) => {
        console.error('Error while trying to retireve projects from folder: ', error)
        return Promise.reject(error);
      });
  },
  createFolderByClientId({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.createFolderByClientId(clientId, payload)
      .then((response) => {
        commit("SET_ACTIVE_FOLDER", {});
        // router.push({
        //   name:"Folder",
        //   params:{id:response._id}
        // });
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(error);
      });
  },
  deleteFolderByClientId({ commit, rootGetters }, folderId) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.deleteFolderByClientId(clientId, folderId)
      .then((response) => {
        commit("SET_ACTIVE_FOLDER", {});
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(error);
      });
  },
  updateFolderByClientId({ commit, rootGetters }, folder) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.updateFolderByClientId(
      clientId,
      folder._id,
      folder
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  addProjectToFolder({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    const folderId = payload.folderId
    const projectId = payload.projectId
    return services.FolderService.addProjectToFolderByClientId(
      clientId,
      folderId,
      projectId
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  removeProjectFromFolder({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    const folderId = payload.folderId;
    const projectId = payload.projectId;
    return services.FolderService.removeProjectFromFolder(
      clientId,
      folderId,
      projectId
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
};

// utils
function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("auth/SET_IS_LOGGED_IN", false, { root: true });
    router.replace({ name: "Login" });
  }
}

function groupProjects(projects) {
  const projectGroupedList = [
    {
      label: "pending",
      status: Utils.PROJECT_STATUS.PENDING,
      list: [],
    },
    {
      label: "active",
      status: Utils.PROJECT_STATUS.ACTIVE,
      list: [],
    },
    {
      label: "closed",
      status: Utils.PROJECT_STATUS.CLOSED,
      list: [],
    },
  ];

  let index;
  projects.forEach((project) => {
    switch (project.status) {
      case Utils.PROJECT_STATUS.PENDING:
        index = 0;
        break;
      case Utils.PROJECT_STATUS.ACTIVE:
        index = 1;
        break;
      case Utils.PROJECT_STATUS.CLOSED:
        index = 2;
        break;
      default:
        index = -1;
        break;
    }
    index > -1 && projectGroupedList[index].list.push(project);
  });

  return projectGroupedList;
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  actions,
  mutations,
};
